import PropTypes from "prop-types"
import React from "react"
import cn from "classnames"

import Title from 'components/Typography/Title/Title'
import Separator from 'components/Separator/Separator'

import styles from './Content.module.scss'

const Content = (props) => (
    <div className={cn(styles.container, {
        [styles.withBackground]: props.withBackground,
        [styles.reversedOnMobile]: props.reversedOnMobile,
        [styles.tabletMq]: props.tabletMq /* switch to mobile display from the tablet media query */
    })} style={props.style}>
        <div className={styles.innerContainer}>
            {props.title && <Title title={props.title} />}

            <div className={styles.columns}>
                {props.columns.map((column, index) => <div style={props.columnsStyle[index]} className={styles.column} key={index}>{column}</div>)}
            </div>

            {props.separator && <Separator />}
        </div>
    </div>
)

Content.propTypes = {
    reversedOnMobile: PropTypes.bool,
    withBackground: PropTypes.bool,
    tabletMq: PropTypes.bool,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    columns: PropTypes.array.isRequired,
    separator: PropTypes.bool
};

Content.defaultProps = {
    reversedOnMobile: false,
    withBackground: false,
    tabletMq: false,
    columns: [],
    separator: false,
    columnsStyle: []
};

export default Content
