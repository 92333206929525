import React, { useState, useCallback } from 'react'
import MediaQuery from 'react-responsive'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import cn from "classnames";

import audi from './images/audi.svg'
import bmw from './images/bmw.svg'
import coldwell from './images/coldwell.svg'
import dominican from './images/dominican.svg'
import easternWashington from './images/eastern-washington.svg'
import edina from './images/edina.svg'
import ford from './images/ford.svg'
import franklin from './images/franklin.svg'
import gm from './images/gm.svg'
import huff from './images/Huff.svg'
import kia from './images/kia.svg'
import loganU from './images/loganU.svg'
import nissan from './images/nissan.svg'
import salisbury from './images/salisbury.svg'
import semonin from './images/semonin.svg'
import suny from './images/suny.svg'
import toyota from './images/toyota.svg'
import woodGros from './images/woodBros.svg'

import styles from './FeaturedBrands.module.scss'

SwiperCore.use([Autoplay]);

const FeaturedBrands = () => {
    // const ref = useRef();
    const [activeTestimonial, setActiveTestimonial] = useState(0)
    // const [swiper, updateSwiper] = React.useState(null)
    //
    const slideTo = useCallback(index => {
        setActiveTestimonial(index)
    }, []);
    //
    // React.useEffect(() => {
    //     if (swiper) {
    //         swiper.slideTo(activeTestimonial)
    //     }
    //
    //     return () => {}
    // }, [activeTestimonial, swiper])

    return (
        <section className={styles.featuredBrands}>
            <MediaQuery minWidth={769}>
                <h2>We are in good company.</h2>
                <div className={styles.logosContainer}>
                    <div className={styles.logo}>
                        <img src={ford} alt='ford' />
                    </div>
                    <div className={styles.logo}>
                        <img src={easternWashington} alt='easternWashington' />
                    </div>
                    <div className={styles.logo}>
                        <img src={coldwell} alt='coldwell' />
                    </div>
                    <div className={styles.logo}>
                        <img src={kia} alt='kia' />
                    </div>
                    <div className={styles.logo}>
                        <img src={salisbury} alt='salisbury' />
                    </div>
                    <div className={styles.logo}>
                        <img src={audi} alt='audi' />
                    </div>
                    <div className={styles.logo}>
                        <img src={franklin} alt='franklin' />
                    </div>
                    <div className={styles.logo}>
                        <img src={toyota} alt='toyota' />
                    </div>
                    <div className={styles.logo}>
                        <img src={nissan} alt='nissan' />
                    </div>
                    <div className={styles.logo}>
                        <img src={dominican} alt='dominican' />
                    </div>
                    <div className={styles.logo}>
                        <img src={woodGros} alt='woodGros' />
                    </div>
                    <div className={styles.logo}>
                        <img src={loganU} alt='loganU' />
                    </div>
                    <div className={styles.logo}>
                        <img src={gm} alt='gmc' />
                    </div>
                    <div className={styles.logo}>
                        <img src={huff} alt='huff' />
                    </div>
                    <div className={styles.logo}>
                        <img src={semonin} alt='semonin' />
                    </div>
                    <div className={styles.logo}>
                        <img src={suny} alt='suny' />
                    </div>
                    <div className={styles.logo}>
                        <img src={edina} alt='edina' />
                    </div>
                    <div className={styles.logo}>
                        <img src={bmw} alt='bmw' />
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                <h2>We are in <br /> good company.</h2>
                <div className={styles.logosContainer}>
                    <Swiper
                        // onSwiper={(swiper) => {
                        //     updateSwiper(swiper)
                        // }}
                        className={styles.wrapperTag}
                        onSlideChange={swiper => {
                            slideTo(swiper.realIndex)
                        }}
                        grabCursor
                        spaceBetween={32}
                        freeMode
                        slidesPerView={2}
                        style={{ height: 400 }}
                        loop
                        speed={5250}
                        autoplay={{
                            "delay": 0,
                            "disableOnInteraction": false,
                            "pauseOnMouseEnter": false,
                            // "reverseDirection": false,
                            // "waitForTransition": false,
                        }}
                    >
                        <SwiperSlide className={styles.swiperSlide}>
                            <div className={styles.logo}>
                                <img src={ford} alt='ford' />
                            </div>
                            <div className={styles.logo}>
                                <img src={easternWashington} alt='easternWashington' />
                            </div>
                            <div className={styles.logo}>
                                <img src={coldwell} alt='coldwell' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <div className={styles.logo}>
                                <img src={kia} alt='kia' />
                            </div>
                            <div className={styles.logo}>
                                <img src={salisbury} alt='salisbury' />
                            </div>
                            <div className={styles.logo}>
                                <img src={audi} alt='audi' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <div className={styles.logo}>
                                <img src={franklin} alt='franklin' />
                            </div>
                            <div className={styles.logo}>
                                <img src={toyota} alt='toyota' />
                            </div>
                            <div className={styles.logo}>
                                <img src={nissan} alt='nissan' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <div className={styles.logo}>
                                <img src={dominican} alt='dominican' />
                            </div>
                            <div className={styles.logo}>
                                <img src={woodGros} alt='woodGros' />
                            </div>
                            <div className={styles.logo}>
                                <img src={loganU} alt='loganU' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <div className={styles.logo}>
                                <img src={gm} alt='gmc' />
                            </div>
                            <div className={styles.logo}>
                                <img src={huff} alt='huff' />
                            </div>
                            <div className={styles.logo}>
                                <img src={semonin} alt='semonin' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiperSlide}>
                            <div className={styles.logo}>
                                <img src={suny} alt='suny' />
                            </div>
                            <div className={styles.logo}>
                                <img src={edina} alt='edina' />
                            </div>
                            <div className={styles.logo}>
                                <img src={bmw} alt='bmw' />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className={styles.bubblesContainer}>
                        {[0, 1, 2, 3, 4, 5].map(value => (
                            <span className={cn(styles.bubble, {[styles.active]: activeTestimonial === value })} />
                        ))}
                    </div>
                </div>
            </MediaQuery>
        </section>
    );
};

export default FeaturedBrands
