import React from 'react'
import styles from './TitleWithLabel.module.scss'

const TitleWithLabel = (props) => {
    return (
        <section className={styles.titleWithLabel}>
            <div className={styles.label}>{props.label}</div>

            <div className={styles.title}>
                {props.title}
            </div>

            {props.content && <div className={styles.content}>{props.content}</div>}
        </section>
    );
};

export default TitleWithLabel
