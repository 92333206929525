import React from 'react'

import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'beautiful-react-hooks'

import Layout from 'layouts/DefaultLayout'
import SEO from 'components/SEO'
import ShiftDemo from 'components/ShiftDemo'
import Content from 'components/Typography/Content/Content'
import HomepageHero from '../page-components/Homepage/HomepageHero/HomepageHero'
import FeaturesWithTestimonial from '../page-components/Homepage/FeaturesWithTestimonial/FeaturesWithTestimonial'
import FeaturedBrands from '../page-components/Homepage/FeaturedBrands/FeaturedBrands'
import FooterCTA from 'components/FooterCTA'
import Separator from '../components/Separator/Separator'
import TitleWithLabel from '../page-components/HowItWorks/TitleWithLabel/TitleWithLabel'

import thumbnail from 'images/shift-generic-thumbnail.jpg'
import communicationMethods from 'images/how_it_works/communication_methods.jpg'
import trackingAndAnalytics from 'images/how_it_works/tracking_and_analytics.jpg'
import trackingAndAnalyticsMobile from 'images/how_it_works/tracking_and_analytics_mobile.jpg'
import dataManagement from 'images/how_it_works/dataManagement.jpg'
import landingPages from 'images/how_it_works/landingPages.png'

import styles from './how-it-works.module.scss'

export default () => {
    const isMobile = useMediaQuery('(max-width: 992px)')

    return (
        <Layout modalType="general" logoText="Marketing">
            <SEO
                title='Direct Marketing Tech for Auto, Higher Ed, Real Estate and Non-profit'
                description="Passionate about bringing your customers back to you, over and over again."
                image={`https://shiftmarketing.io${thumbnail}`}
                canonical={'https://shiftmarketing.io'}
                ogUrl={'https://shiftmarketing.io'}
                siteName={'Shift Marketing'}
            />
            <HomepageHero />
            <FeaturesWithTestimonial />
            <Content
                tabletMq
                separator={true}
                style={{ marginTop: 0 }}
                columnsStyle={[{ marginTop: 0 }, isMobile ? {} : { marginTop: 100 }]}
                columns={[
                    <img
                        // className={styles.communicationImg}
                        src={dataManagement}
                        alt="Data Management"
                    />,
                    <TitleWithLabel
                        label="Data Management"
                        content={
                            <p style={{ lineHeight: 1.76 }}>
                                It all starts with the data. From cleansing, advanced filters, segmentation, to our prorietary lead scoring algorithm, at Shift Marketing we look to isolate the prospects in your database most likely to respond. Following a data driven process for prospect selection will help you maximize your response rate, save you money by avoiding statistically disinterested customers and, as a result, contribute to maximizing your marketing ROI.
                            </p>
                        }
                        title={
                            <strong>
                                Taking the guesswork out of database marketing.
                            </strong>
                        }
                    />
                ]}
            />
            <Content
                tabletMq
                reversedOnMobile
                separator={true}
                columns={[
                    <TitleWithLabel
                        label="Communication Methods"
                        title={
                            <strong>
                                Beautiful, fully-integrated <br /> and personalized
                                communications.
                            </strong>
                        }
                        content={
                            <p style={{ lineHeight: 1.76 }}>
                                With the ability to use every one-to-one direct
                                marketing channel available, including direct
                                mail, email, text messaging, ringless voicemail
                                and call center support, we help you cut through
                                the marketing noise and deliver an effective,
                                personalized message to inspire your contacts to
                                act.
                            </p>
                        }
                    />,
                    <img
                        className={styles.communicationImg}
                        src={communicationMethods}
                        alt="Communication Methods"
                    />,
                ]}
            />
            <Content
                tabletMq
                separator={true}
                columns={[
                    <img
                        className={styles.leadGenImg}
                        src={landingPages}
                        alt="Landing Pages"
                        style={{ maxWidth: 370 }}
                    />,
                    <TitleWithLabel
                        label="Landing Pages"
                        title={
                            <strong>
                                Nurture your prospects with impeccably designed and optimized lead generating landing pages.
                            </strong>
                        }
                        content={
                            <p style={{ lineHeight: 1.76 }}>
                                At Shift Marketing, we care deeply about the customer experience. The prospects engaging with your marketing campaigns are not only your next sale, they are the reason you exist as a company or institution. At Shift Marketing, we uphold the highest standards for your brand and earn your client's loyalty by offering a beautiful, easy-to-use and optimized digital customer experience in order to maximize results and leave a positive impression.
                            </p>
                        }
                    />,
                ]}
            />
            <Content
                tabletMq
                reversedOnMobile
                separator={false}
                columns={[
                    <TitleWithLabel
                        label="Tracking and Analytics"
                        title={
                            <strong>
                                If you can't measure it, you can't manage it.
                            </strong>
                        }
                        content={
                            <p style={{ lineHeight: 1.76 }}>
                                As marketers, we need to know if what we are
                                doing is working. It is simple, but its
                                importance cannot be overstated - If you can’t
                                measure it, you can’t manage it. Our Shift One
                                Analytics Dashboard and Micro-CRM will help you
                                manage your campaign, grade performance and
                                apply learnings to future efforts. Work with
                                Shift Marketing's easy-to-use tracking and
                                analytics tools to help you maximize your
                                marketing and drive results.
                            </p>
                        }
                    />,
                    <>
                        <MediaQuery minWidth={993}>
                            <img
                                src={trackingAndAnalytics}
                                alt="Tracking And Analytics"
                                style={{ maxWidth: 655 }}
                            />
                        </MediaQuery>

                        <MediaQuery maxWidth={992}>
                            <img
                                src={trackingAndAnalyticsMobile}
                                alt="Tracking And Analytics"
                            />
                        </MediaQuery>
                    </>,
                ]}
            />
            <Separator container/>
            <ShiftDemo/>
            <FeaturedBrands/>
            <FooterCTA modalType="general"/>
        </Layout>
    )
}
