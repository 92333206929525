import React from 'react'
import cn from 'classnames'
import MediaQuery from 'react-responsive'


import { Store } from 'store'
import { setModalOpen } from "layouts/DefaultLayout";

import styles from './HomepageHero.module.scss'

const HomepageHero = () => {
    const {dispatch} = React.useContext(Store)

    return (
        <section className={styles.hero}>
            <div className={styles.left}>
                <div className={styles.leftInner}>
                    <MediaQuery minWidth={769}>
                        <h1 className={styles.title}>
                            Passionate about bringing your customers back to you, <span>over and over again.</span>
                        </h1>
                        <p className={styles.subtitle}>
                            Elevate your database marketing with Shift Marketing.
                        </p>
                        <button onClick={() => setModalOpen(dispatch, 'general')} className="sh-button-primary __large" style={{ width: '210px' }}>Book a demo</button>
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                        <h1 className={styles.title}>
                            Elevate your <br /> database marketing with <br /> Shift Marketing.
                        </h1>
                    </MediaQuery>
                </div>
            </div>

            <div className={styles.right}>
                <div className={styles.floatingFeatures}>
                    <div className={cn(styles.floatingFeature, styles.featureData)}>Data</div>
                    <div className={cn(styles.floatingFeature, styles.featureCommunicate)}>Communicate</div>
                    <div className={cn(styles.floatingFeature, styles.featureLeadgen)}>Lead Generation</div>
                    <div className={cn(styles.floatingFeature, styles.featureTrack)}>Track Results</div>
                </div>
            </div>
            <MediaQuery maxWidth={768}>
                <div className={styles.btnContainer}>
                    <button onClick={() => setModalOpen(dispatch, 'general')} className="sh-button-primary __large" style={{ width: '210px' }}>Book a demo</button>
                </div>
            </MediaQuery>
        </section>
    );
};

export default HomepageHero
