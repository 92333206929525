import PropTypes from "prop-types"
import React from "react"

import styles from './Title.module.scss'

const Title = (props) => (
    <h3 className={styles.title}>
        {props.title}
    </h3>
)

Title.propTypes = {
  title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
  ]).isRequired
}

export default Title
