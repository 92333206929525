import * as React from 'react'
import MediaQuery from 'react-responsive'

import iconCustomerData from './images/icon_customer_data.svg'
import iconCommunication from './images/icon_communication.svg'
import iconLeadGen from './images/icon_lead_gen.svg'
import iconTracking from './images/icon_tracking.svg'

import styles from './FeaturesWithTestimonial.module.scss'

const Testimonials = React.lazy(() => import('../../../page-components/Homepage/Testimonials/Testimonials'))

const isSSR = typeof window === 'undefined'

function FeaturesWithTestimonial () {
    return (
        <section className={styles.featuresWithTestimonial}>
            <div className={styles.features}>
                <h2>How we work at Shift Marketing.</h2>
                <div className={styles.featureBlocks}>
                    <div className={styles.feature}>
                        <div className={styles.featureIcon}>
                            <img src={iconCustomerData} alt="" />
                        </div>

                        <h4 className={styles.featureTitle}>Customer data</h4>

                        <p className={styles.featureDescription}>
                            Target the right clients at <br /> the right time with predictive <br /> analytics and segmentation.
                        </p>

                        {/* <Link to="/how-it-works">Learn more</Link> */}
                    </div>

                    <div className={styles.feature}>
                        <div className={styles.featureIcon}>
                            <img src={iconCommunication} alt="" />
                        </div>

                        <h4 className={styles.featureTitle}>Communication</h4>

                        <p className={styles.featureDescription}>
                            Direct marketing channels like <br /> direct mail, email, text (and <br /> more!) to engage your clients.
                        </p>

                        {/* <Link to="/how-it-works">Learn more</Link> */}
                    </div>

                    <div className={styles.feature}>
                        <div className={styles.featureIcon}>
                            <img src={iconLeadGen} alt="" />
                        </div>

                        <h4 className={styles.featureTitle}>Landing pages</h4>

                        <p className={styles.featureDescription}>
                            Nurture your leads and offer <br /> an unparalleled digital <br /> customer experience.
                        </p>

                        {/* <Link to="/how-it-works">Learn more</Link> */}
                    </div>

                    <div className={styles.feature}>
                        <div className={styles.featureIcon}>
                            <img src={iconTracking} alt="" />
                        </div>
                        <h4 className={styles.featureTitle}>
                            <MediaQuery minWidth={769}>
                                Reporting and analytics
                            </MediaQuery>
                            <MediaQuery maxWidth={768}>
                                Analytics
                            </MediaQuery>
                        </h4>

                        <p className={styles.featureDescription}>
                            Track every meaningful <br /> interaction, and make the most <br /> of your database marketing.
                        </p>

                        {/* <Link to="/how-it-works">Learn more</Link> */}
                    </div>
                </div>

                {/* <div className={styles.learnMoreMobile}>
                    <Link to="/how-it-works" className='sh-button-primary'>Learn more</Link>
                </div> */}
            </div>

            <div>
                {!isSSR &&
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <Testimonials/>
                    </React.Suspense>
                }
            </div>

        </section>
    )
}

export default FeaturesWithTestimonial
